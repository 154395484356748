tr.child {
    outline: black solid 5px;
}

.MuiTableCell-root {
	width: auto !important;
	min-width: fit-content !important;
}

.react-sortable-helper {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.react-sortable-helper .MuiTableCell-root {
	display: table-cell !important;
	width: inherit !important;
	height: inherit !important;
	text-align: center !important;
	word-wrap: break-word !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(1) {
	width: 14% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(2) {
	width: 10% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(3) {
	width: 21% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(4) {
	width: 8% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(5) {
	width: 8% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(6) {
	width: 8% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(7) {
	width: 8% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(8) {
	width: 8% !important;
}

.react-sortable-helper .MuiTableCell-root:nth-child(9) {
	width: 8% !important;
}