/**
 * Main application sass file that contains all the application styling
 */

/*// import bryntum theme*/
@import "~@bryntum/scheduler/scheduler.stockholm.css";

#innerAppContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  background-color: #d5d5d5;
}

#schedulerContainer {
  display: flex;
}

.bryntumScheduler {
  border: solid 1px #717171;
  border-radius: 2.5px;
}

#unqueuedItemsContainer {
  flex: 1;
  /* display: flex; */
  /* border: solid 1px #e9eaeb; */
  /* margin: 10px; */
  /* background-color: white; */
}

.flex-grow {
  display: flex;
  flex: 1;
}

.b-gridbase.b-autoheight {
  /*// Override the Scheduler to allow the grid to auto scale height*/
  flex: 1 !important;
}

/*// Making all of these elements !important because material-ui applies*/
/*// inline styling to its components. You could also override this at*/
/*// the component level but I'm not sure where that will be in the source code.*/
.custom-dragged-element {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  color: white !important;
  vertical-align: center !important;
  padding-left: 10px;
  border-radius: 5px;
  --b-fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
  font-weight: 500 !important;
  font-size: 13px !important;
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: solid 1px;
  border-color: black !important;
  opacity: 80%;
  /* background-color: rgb(189 175 108) !important; */
}

/* .counterweight-drag-style {
  background-color: rgb(189 175 108) !important;
}

.order-drag-style {
  background-color: rgb(189 175 108) !important;
} */

.loadedOrderInArm {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgb(53, 152, 220);
  margin: 2.5px 2.5px 0 0;
}

/*// Hard break flag styling*/
.hard-break-scheduler {
  background-color: #d52929 !important;
  border-color: #d52929 !important;
}

.empty-scheduler-header {
  cursor: default !important;
}

.empty-scheduler-header:hover {
  background-color: inherit !important;
}

/*// There is a bug if you add 'cls' to the showCurrentTimeLine object in the*/
/*// scheduler > features > timeRanges section, it will allow you to drag the*/
/*// flag which breaks the page. Internal error on Bryntum's side so I'm*/
/*// just overwritting the styles manually*/
.b-grid-header .b-sch-timerange.b-sch-current-time {
  background-color: #219534 !important;
}
.b-timeline-subgrid .b-sch-current-time {
  border-left-color: #219534 !important;
}

.scheduler-header-cell {
  border-bottom: solid 1px #e0e0e0;
  border-right: solid 1px #e0e0e0;
  border-left: solid 1px #e0e0e0;
}

.scheduler-header-cell:last-child {
  border-right: none;
}

#view-preset-select {
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-left: 15px !important;
  padding-bottom: 5px !important;
}

.scheduler-bar {
  border-radius: 5px;
  border: solid 1px;
  border-color: black !important;
  background-color: #eadc96 !important;
  color: black !important;
}

.scheduler-bar-currently-molding {
  background-color: #90EE90 !important;
  color: black !important;  
}

.scheduler-bar-counter-weight {
  /* color: black !important; */
  background-color: rgb(167, 167, 167) !important;
}

.counter-weight-button {
  display: flex;
  border: solid 1px rgb(100, 100, 100);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgb(167, 167, 167);
  align-items: center;
  color: white;
  vertical-align: center;
  padding-left: 10px;
  border-radius: 5px;
  --b-fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  user-select: none;
}

/* BORDER RADIUS */

.bryntumScheduler {
	border: solid 1px #717171;
	border-radius: 12px;
	overflow: hidden;
}

/* Add styles for the grid containers */
.b-grid-body-container {
	border-radius: 12px !important;
	overflow: hidden !important;
}

.b-timeline-subgrid {
	border-radius: 12px !important;
	overflow: hidden !important;
}

.b-grid-body {
	border-radius: 12px !important;
}

/* Round the header separately if needed */
.b-grid-header-container {
	border-top-left-radius: 12px !important;
	border-top-right-radius: 12px !important;
}

/* Ensure the vertical header also gets rounded */
.b-grid-vertical-scroller {
	border-top-left-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
}

/* If you want to round specific parts of the timeline */
.b-sch-timeaxis-cell {
	border-top-right-radius: 12px;
}
