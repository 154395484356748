
div.progress {
  height: 100%;
  background-color: #000000;
  opacity: 95;
  animation: progress-bar 5s infinite;
}

@keyframes progress-bar {
  from {width: 0;}
  to {width: 100%;}
}