
/* this file overrides modal overflow-y auto rule which stops dropdowns from extending outside of the modal */
.MuiPaper-root {
  overflow-y: visible !important;
}

.MuiDialog-paper {
  overflow-y: visible !important;
}

.MuiDialog-container {
  overflow-y: visible !important;
}
