/* color tweaks for drag-drop import in dark mode */

.file-upload-dark-mode div span {
  color: white !important;
}

.file-upload-dark-mode svg path {
  fill: #90caf9 !important;
}

.file-upload-dark-mode {
  border: dashed 2px #90caf9 !important;
}
